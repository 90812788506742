import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssetsModule } from '@app/_assets';
import { TranslateModule } from '@app/_modules/translate';
import {
	DigiSignGroupType,
	DigiSignItem,
	DigiSignService,
} from '@app/_services/digi-sign.service';
import { ButtonComponent } from '@app/components/button/button.component';
import { IconComponent } from '@app/components/icon/icon.component';
import { tap } from 'rxjs';
import { DigiSignGroup } from './digi-sign-overlay.model';
import { TranslateService } from '@app/_modules/translate/translate.service';
import {
	TeachingQualification,
	TeachingQualificationEducation,
	TeachingWork,
} from '@app/modules/my-data/my-data-teaching-detail-view/my-data-teaching-detail-view.model';
import { isDateInPast } from '@app/_core/utility';
import {
	ExternalQualifications,
	Studies,
} from '@app/components/studies-list/studies-list.model';

@Component({
	selector: 'digi-sign-overlay',
	templateUrl: './digi-sign-overlay.template.html',
	styleUrls: ['./digi-sign-overlay.styles.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		OverlayModule,
		ButtonComponent,
		TranslateModule,
		IconComponent,
		NgIf,
		NgFor,
		NgTemplateOutlet,
		AssetsModule,
	],
})
export class DigiSignOverlayComponent implements AfterViewInit {
	private overlay: OverlayRef;
	protected isOpen = false;
	protected isExpanded = false;
	protected items = [];
	protected groups: DigiSignGroup[] = [];
	protected downloading = false;
	@ViewChild('overlayTemplate') overlayTemplate: TemplateRef<unknown>;
	constructor(
		private overlayService: Overlay,
		private _viewContainerRef: ViewContainerRef,
		private destroyRef: DestroyRef,
		private digiSignService: DigiSignService,
		private translate: TranslateService
	) {}
	ngAfterViewInit(): void {
		this.digiSignService.addedItems
			.pipe(
				// Open overlay if there's at least one item
				tap((items) => this.handleToggle(items?.length > 0)),
				// Cleanup
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe((items) => {
				this.items = items;
				this.createGroups(items);
			});
	}
	openOverlay() {
		// Create overlay relative to content element
		const appContent =
			globalThis?.document?.querySelector<HTMLElement>('.app-content');
		// Get the browser scrollbar width to avoid overlap
		const scrollWidth =
			(appContent?.offsetWidth ?? 0) - (appContent?.clientWidth ?? 0);
		// Place overlay at the bottom right corner of screen
		const positionStrategy = this.overlayService
			.position()
			.flexibleConnectedTo(appContent)
			.withPositions([
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'bottom',
				},
			])
			// Offset by scrollbar width
			.withDefaultOffsetX(-scrollWidth);
		this.overlay = this.overlayService.create({ positionStrategy });
		const portal = new TemplatePortal(
			this.overlayTemplate,
			this._viewContainerRef
		);
		this.overlay.attach(portal);
		this.isOpen = true;
	}

	handleToggle(hasItems: boolean) {
		if (!hasItems && this.isOpen) {
			// Get rid of overlay
			this.overlay?.dispose();
			this.isOpen = false;
		} else if (hasItems && !this.isOpen) {
			this.openOverlay();
		}
	}

	createGroups(items: DigiSignItem[]) {
		this.groups = items.reduce<DigiSignGroup[]>((groups, item) => {
			// some hack idk
			const existingGroup = groups.find(
				(entry) => entry.group === item.digiSignGroup
			);

			const mapped = { id: item.id, name: this.getItemName(item) };
			if (existingGroup) {
				existingGroup.items.push(mapped);
				return groups;
			}

			return [
				...groups,
				{
					label: this.getGroupName(item.digiSignGroup),
					group: item.digiSignGroup,
					items: [mapped],
				},
			];
		}, []);
	}

	removeItem(item: DigiSignItem) {
		this.digiSignService.removeItem(item);
	}

	clearList() {
		this.digiSignService.clearItems();
	}

	downloadItems() {
		this.downloading = true;
		this.digiSignService.downloadAddedItems(() => (this.downloading = false));
	}

	getGroupName(groupType?: DigiSignGroupType) {
		return `digi_sign.${groupType}`;
	}

	getItemName(item: DigiSignItem) {
		const workType = item as TeachingWork;
		const qualType = item as TeachingQualification;
		const eduType = item as TeachingQualificationEducation;
		const extType = item as ExternalQualifications;
		const schType = item as Studies;

		let baseString = `${
			qualType.nimetus ||
			workType.oppeasutus ||
			extType.oppeasutuseNimi ||
			extType.oppeasutuseNimiTranslit ||
			extType.oppeasutuseNimiMuusKeeles
		}`;

		if (workType?.ametikohtLopp && isDateInPast(workType.ametikohtLopp)) {
			baseString += `, ${this.parseTypeTranslation('LOPETANUD')} ${
				workType.ametikohtLopp
			}`;
		}

		if (extType.valjaandmKp) {
			baseString +=
				', ' +
				this.parseTypeTranslation('LOPETANUD') +
				' ' +
				extType.valjaandmKp;
		}

		if (schType.staatus || schType.oppLopp) {
			baseString += schType.staatus
				? ', ' + this.parseTypeTranslation(schType.staatus)
				: '';
			baseString += schType.oppLopp ? ' ' + schType.oppLopp : '';
		}

		if (qualType?.aasta) {
			baseString += `, ${qualType.aasta}`;
		}

		if (eduType?.aastaLopetanud) {
			baseString += `, ${eduType.aastaLopetanud}`;
		}

		return baseString;
	}

	public parseTypeTranslation(type) {
		const translation = this.translate.get(`frontpage.${type}`).toString();
		if (translation.includes(`frontpage.${type}`)) {
			return type.toLocaleLowerCase();
		}
		return translation.toLocaleLowerCase();
	}
}
