<ng-template #overlayTemplate>
	<div
		class="digi-sign-overlay__wrapper"
		[class.digi-sign-overlay__wrapper--expanded]="isExpanded"
	>
		<button
			class="digi-sign-overlay__expand-button"
			htm-button
			theme="plain"
			(click)="isExpanded = !isExpanded;"
			[attr.aria-expanded]="isExpanded"
			aria-controls="digi-sign-overlay-contents"
			data-test-id="digi-sign-overlay-expand-button"
		>
			<span
				>{{(isExpanded ? 'digi_sign.hide' : 'digi_sign.show') |
				translate}}</span
			>
			<icon
				[glyph]="isExpanded ? 'chevron-down': 'chevron-up'"
				aria-hidden="true"
			></icon>
		</button>
		<div class="digi-sign-overlay__title">
			<span>{{'digi_sign.sign_and_download' | translate}}</span>
			@if (isExpanded) {
				<span class="digi-sign-overlay__help-tooltip"
					><icon
						glyph="help-circle"
						matTooltip="{{ 'digi_sign.sign_and_download_help' | translate}}"
						matTooltipPosition="below"
					></icon
				></span>
			}
		</div>
		<div id="digi-sign-overlay-contents" class="digi-sign-overlay__contents">
			<ng-container
				[ngTemplateOutlet]="isExpanded ? expandedView : collapsedView"
			>
			</ng-container>
		</div>
	</div>
</ng-template>

<ng-template #expandedView>
	<div class="digi-sign-overlay__list">
		@for (group of groups; track group.typeName) {
		<div class="digi-sign-overlay__group">
			<span class="digi-sign-overlay__group__title"
				>{{ group.label | translate }} ({{ group.items.length }}):</span
			>
			@for (item of group.items; track item.id; let isLast = $last) {
			<div class="digi-sign-overlay__item">
				<span>{{item.name}}</span>
				<button
					class="digi-sign-overlay__remove-button"
					htm-button
					theme="plain"
					(click)="removeItem(item)"
					[attr.data-test-id]="'digi-sign-overlay-remove-item-button-' + item?.id"
					[attr.aria-label]="'digi_sign.remove_study' | translate"
				>
					<icon glyph="x" aria-hidden="true"></icon>
				</button>
			</div>
			<div
				class="digi-sign-overlay__separator"
				*ngIf="!isLast && items?.length > 1"
			></div>
			}
		</div>
		}
	</div>
	<button
		class="digi-sign-overlay__download-button"
		htm-button
		*ngIf="!downloading"
		(click)="downloadItems()"
	>
		{{'digi_sign.download' | translate}}
	</button>
	<loader *ngIf="downloading"></loader>
	<button
		class="digi-sign-overlay__cancel-button"
		htm-button
		theme="plain"
		(click)="clearList()"
	>
		{{'digi_sign.cancel' | translate}}
	</button>
</ng-template>

<ng-template #collapsedView>
	<div class="digi-sign-overlay__count">
		{{'digi_sign.added_to_container' | translate}}: {{items?.length}}
	</div>
</ng-template>
