import { Routes } from '@angular/router';
import { translateRoutes } from '@app/_core/router-utility';
import { AuthService } from '@app/_services';
import { canMatchMyData } from './route-matchers/my-data-matcher';

export const MyDataRoutes: Routes = translateRoutes([
	{
		path: 'õpingud/:id',
		loadComponent: () =>
			import(
				'./my-data-studies-detail-view/my-data-studies-detail-view.component'
			).then((mod) => mod.MyDataStudiesDetailViewComponent),
		canActivate: [AuthService],
	},
	{
		path: 'õpetamine/:type',
		children: [
			{
				path: '',
				loadComponent: () =>
					import(
						'./my-data-teaching-detail-view/my-data-teaching-detail-view.component'
					).then((mod) => mod.MyDataTeachingDetailViewComponent),
			},
			{
				path: 'vaata',
				loadComponent: () =>
					import(
						'./my-data-teaching-item-view/my-data-teaching-item-view.component'
					).then((mod) => mod.MyDataTeachingItemViewComponent),
			},
		],
		canActivate: [AuthService],
		canMatch: [canMatchMyData],
	},
	{
		path: 'lõpudokumendid/tunnistused/:id',
		loadChildren: () =>
			import(
				'../certificates/containers/certificates-dashboard-detail/certificates-dashboard-detail.module'
			).then((m) => m.CertificatesDashboardDetailModule),
		data: {
			from: 'final_documents',
		},
	},
	{
		path: 'lõpudokumendid/:certificateNr/:accessorCode',
		loadComponent: () =>
			import('../my-data/final-document/final-document.component').then(
				(c) => c.MyDataFinalDocument,
			),
		data: {
			from: 'final_documents',
		},
	},
	{
		path: 'lõpudokumendid/:id',
		loadComponent: () =>
			import('../my-data/final-document/final-document.component').then(
				(c) => c.MyDataFinalDocument,
			),
		data: {
			from: 'final_documents',
		},
	},
	{
		path: ':id',
		loadComponent: () =>
			import('./my-data-view/my-data-view.component').then(
				(mod) => mod.MyDataViewComponent,
			),
		canMatch: [canMatchMyData],
	},
]);
