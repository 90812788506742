/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dedrupalize } from '@app/_core/dedrupalize';

@Injectable()
export class DedrupalizeInterceptor implements HttpInterceptor {
	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					// Do not intercept file responses
					if (event.body instanceof Blob) {
						return event;
					}
					if (event.url.includes('/api') && event.body && !event.body.data) {
						const parsed = Dedrupalize.recurseObject(event.body);
						return event.clone({
							body: parsed,
						});
					}
				}
				return event;
			})
		);
	}
}
