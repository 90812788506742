export enum FeatureFlags {
	DARK_MODE = 'darkmode',
	MY_DATA_ENABLED = 'my_data_enabled',
	MY_DATA_NEW_STUDY_VIEW_ENABLED = 'my_data_new_study_view_enabled'
}

const FeatureFlagExpectedValues = {
	[FeatureFlags.DARK_MODE]: true,
	[FeatureFlags.MY_DATA_ENABLED]: true,
	[FeatureFlags.MY_DATA_NEW_STUDY_VIEW_ENABLED]: true
} satisfies Record<FeatureFlags, unknown>;

export type FeatureFlagExpectedValuesTypes = typeof FeatureFlagExpectedValues;
