/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FourOhFourInterceptor implements HttpInterceptor {
	constructor(private router: Router, private route: ActivatedRoute) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error) => {
				if (
					error.status === 404 &&
					error?.url?.startsWith(`${environment.API_URL}/api/`) &&
					['/detailview', '/list'].some((path) => error.url.includes(path))
				) {
					// Include queryparams in redirect
					const urlTree = this.router.createUrlTree(['404'], { queryParams: this.route.snapshot.queryParams });
					this.router.navigateByUrl(urlTree, { skipLocationChange: true });
				}
				return throwError(() => error);
			}),
		);
	}
}
