<div class="accordion__wrapper" [class.accordion__wrapper--active]="this.styleState" #accordionWrapper>
  <div class="accordion__title-wrapper" >
    <div class="accordion__title" role="button" tabindex="0" [attr.aria-expanded]="active" aria-disabled="false"
      (keyDown.enter)="openAccordion()" (keyDown.space)="openAccordion($event)"
      [attr.data-test-id]="'accordion-toggle-' + (title() | slugify)"
      [attr.id]="slugifiedTitle()"
      (click)="openAccordion()" ripple="light"
      [class.accordion__title--additional-button]="additionalButton"
      >
      <div [innerHTML]="title()"></div>
    </div>
    <div class="accordion__additional-button" *ngIf="additionalButton">
      <ng-container
        [ngTemplateOutlet]="additionalButton"
        [ngTemplateOutletContext]="additionalButtonContext">
      </ng-container>
    </div>
    <button
      class="accordion__indicator"
      [class.accordion__indicator--active]="active"
      [class.accordion__indicator--additional-button]="additionalButton"
      [attr.aria-expanded]="active" aria-disabled="false"
      (keyDown.enter)="openAccordion($event)" (keyDown.space)="openAccordion($event)"
      [attr.data-test-id]="'accordion-arrow-toggle-' + (title() | slugify)"
      (click)="openAccordion()"
      >
      <icon glyph="chevron-down"></icon>
    </button>
    <!--/accordion__indicator-->
  </div>
  <!--/accordion__title-->
  <div class="accordion__content" [@toggleBody]="active ? 'expanded' : 'collapsed'"
       (@toggleBody.done)="animationDone()"
       (@toggleBody.start)="animationStart()">
    <div class="accordion__inline">
      <ng-container *ngIf="active">
        <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
      </ng-container>
    </div>
    <!--/accordion__inline-->
  </div>
  <!--/accordion_Content-->
</div>
<!--/accordion__wrapper-->
