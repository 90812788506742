import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

const mobileBreakpoint = '(max-width: 1023.98px)';
const desktopBreakpoint = '(min-width: 1024px)';
@Injectable({providedIn: 'root'})
export class BreakpointService {
	public isMobile$ = this.breakpointObserver?.observe(mobileBreakpoint);
	public isDesktop$ = this.breakpointObserver?.observe(desktopBreakpoint);
	public isMobile = toSignal(this.isMobile$.pipe(map(val => val?.matches)));
	public isDesktop = toSignal(this.isDesktop$.pipe(map(val => val?.matches)));
	
	constructor(private breakpointObserver: BreakpointObserver) {}
}
